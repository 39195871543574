<template>
  <div>
    <Error :error="error" />
  </div>
</template>

<script lang="ts">
import Error from '~/component/state/error.vue'

export default {
  components: {
    Error
  },
  props: {
    error: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>